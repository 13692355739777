/* eslint-disable @typescript-eslint/naming-convention */
import { type FC, useEffect, useState, Fragment } from 'react'
import { HomePageLayout } from '@layout/index'
import { useTheme } from '@mui/material'
import {
  Box,
  Grid,
  Typography,
  Button,
  Icon,
  Container,
  CircleLoader,
  Tooltip
} from '@ntpkunity/controls'
import {
  FullPageLoader,
  HeroBanner,
  MainContent,
  ProductPackages,
  SupportWrap
} from './detailPageStyle'
import {
  CustomPlan,
  useCreateFreeTrialSubscription,
  useGetCustomPackage,
  useGetProductByName,
  useGetProductPricingByProductId,
  useGetSubscriptions
} from '@modules/product'
import {
  ACCESS_TOKEN_KEY,
  AccountType,
  IS_LOGGED_IN,
  Product,
  ProductStatus,
  STRIPE_USER_CREATED,
  packages,
  FREE_TRIAL_PRODUCT,
  packageAlreadyAvailed,
  productAdded,
  customPackageDescription,
  verificationEmailSent,
  className,
  SIGNUP_STEPS,
  Steps,
  productState,
  unsubscribeConfirmation,
  DialogHeading,
  ButtonType,
  PaymentMethod
} from '@shared/constants'
import {
  convertToAmount,
  convertToAmountWithOutSign,
  getItem,
  getItemByKey,
  hashToId,
  isLoggedIn,
  openInNextWindow,
  removeItembyKey,
  scrollOnTop,
  scrollToId,
  setItem,
  setItembyKey
} from '@shared/helper'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { APP_ROUTES } from '@router/path'
import { FeatureComponent as Feature } from '@modules/homePage/components/feature/feature'
import {
  type IFeatureDetails,
  type IProduct,
  type IProductPriceDetails,
  type IProductResponse
} from '@shared/typings'
import { useAddToCart } from '@modules/product/services/cart/cartService'
import { useStoreContext } from 'store/storeContext'
import PlayIcon from '../../../../shared/assets/images/ic-play.svg'
import StarterPkgImage from '../../../../shared/assets/images/starter-pkg-img.svg'
import BasicPkgImage from '../../../../shared/assets/images/basic-pkg-img.svg'
import StandardPkgImage from '../../../../shared/assets/images/standard-pkg-img.svg'
import PremiumPkgImage from '../../../../shared/assets/images/premium-pkg-img.svg'
import ApiDocsImage from '../../../../shared/assets/images/sp-api-docs-img.svg'
import MarketingImage from '../../../../shared/assets/images/sp-marketing-img.svg'
import CustomPlanImage from '../../../../shared/assets/images/custom-plan-img.svg'
import {
  NewsLetterComponent,
  FaqsComponent,
  ProductDemoVideoComponent,
  JoinWaitList,
  BlogItemComponent,
  ProductLogoStripComponent
} from '@modules/homePage/components'
import {
  UnsubEmailVerificationPopup,
  UnsubscribeFeedbackPopup,
  useConfirmUnsubscription
} from '@modules/unsubscription'
import { HowToConnectComponent } from '@modules/howToConnect/components'
import { TestimonialsComponent } from '@modules/testimonial/components'
import { ProductFeaturesComponent } from '@modules/productFeature/components'
import { useGetProductFeatures } from '@modules/productFeature/components/productFeatures/productFeaturesService'
import { FadeAnimation } from '@shared/components'
import { useGetTestimonials } from '@modules/homePage/services/testimonials/testimonialsService'
import { DecisionPrompt } from '@shared/components/DecisionPrompt/decisionPrompt'
import { useGetBlogByTag } from '@modules/blog'
import { WhyChooseProductComponent } from '@modules/whyChooseProduct/components'

export const DetailPage: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const { productName } = useParams()

  const { search } = location as any
  const queryParams = new URLSearchParams(search)
  const _showCustomPackage = queryParams.get('showCustomPackage')
  const _showDemoVideo = queryParams.get('demo')

  if (productName?.length === 0) {
    navigate(APP_ROUTES.NEW_HOME_PAGE)
  }
  const showCustomPackage = _showCustomPackage?.toLowerCase() === 'true'
  const [products, setProducts] = useState<IProductPriceDetails[]>([])
  const [product, setProduct] = useState<IProduct>()

  const { data: productByName } = useGetProductByName(productName)
  const { data, isLoading: featuresLoading } = useGetProductFeatures(product?.id)
  const { data: TESTIMONIAL_DATA, isLoading: testimonialsLoading } = useGetTestimonials(product?.id)
  const { data: productPricing, isLoading: productPricingLoading } = useGetProductPricingByProductId(product?.id)
  const { data: subscriptions, isLoading: subscriptionsLoading } = useGetSubscriptions(product?.id)
  const { data: customPackages, isLoading: customPackagesLoading } = useGetCustomPackage(product?.id)
  const { data: blog } = useGetBlogByTag(productName?.toLowerCase())

  const { mutate: createFreeSubscription, isLoading: creatingSubscritpion } = useCreateFreeTrialSubscription()
  const { mutate: confirmUnsubscription, isLoading } = useConfirmUnsubscription()

  const [isFreeTrialSubscribed, setIsFreeTrialSubscribed] = useState(false)
  const [isPaidPackageSubscribed, setIsPaidPackageSubscribed] = useState(false)
  const [subscribedProducts, setSubscribedProducts] = useState<IProductResponse[]>()
  const [subscribedProduct, setSubscribedProduct] = useState<IProductResponse>()
  const [selectedPackage, setSelectedPackage] = useState<IProductResponse>()
  const [productFeatures, setProductFeatures] = useState<IFeatureDetails[] | undefined>()

  useEffect(() => {
    if (productByName != null) {
      setProduct(productByName)
    }
  }, [productByName])

  const [showCustomPlan, hideCustomPlan] = useModal(
    () => (
      <CustomPlan hide={hideCustomPlan} productName={product?.name ?? ''} />
    ),
    [product]
  )

  const [showDemoVideo, hideDemoVideo] = useModal(
    () => (
      <ProductDemoVideoComponent
        hide={hideDemoVideo}
        videoLink={product?.demo_video_url ?? ''}
      />
    ),
    [product]
  )

  const [packageOrder, setPackageOrder] = useState<number>()
  const [currentPricingId, setCurrentPricingId] = useState<number>()
  const [currentProduct, setCurrentProduct] = useState<IProduct>()
  const [, setAccountType] = useState<string>()
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null

    if (location.hash.length > 0) {
      if (
        loaded &&
        !featuresLoading &&
        !testimonialsLoading &&
        !productPricingLoading &&
        !subscriptionsLoading &&
        !customPackagesLoading
      ) {
        timeoutId = setTimeout(() => { scrollToId(hashToId(location.hash)) }, 1500)
      }
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [
    location.hash,
    loaded,
    featuresLoading,
    testimonialsLoading,
    productPricingLoading,
    subscriptionsLoading,
    customPackagesLoading
  ])

  useEffect(() => {
    scrollOnTop()
  }, [productName])

  useEffect(() => {
    if (Array.isArray(subscriptions) && subscriptions?.length > 0) {
      setIsFreeTrialSubscribed(
        subscriptions?.filter(
          (prod) =>
            prod.package_type?.toLowerCase() === AccountType.FREE &&
            prod.product_id === product?.id
        ).length > 0
      )
      setIsPaidPackageSubscribed(
        subscriptions?.filter(
          (prod) =>
            prod.package_type?.toLowerCase() === AccountType.PAID &&
            prod.product_id === product?.id
        ).length > 0
      )
      setSubscribedProducts(subscriptions?.filter((prod) => prod.is_active))
    }
  }, [subscriptions, product])

  useEffect(() => {
    if (Array.isArray(subscribedProducts) && subscribedProducts?.length > 0) {
      const isUserLoggedIn = getItem(IS_LOGGED_IN) as unknown as boolean
      if (isUserLoggedIn) {
        setSubscribedProduct(
          subscribedProducts?.filter(
            (product) =>
              product.product_name.toLowerCase() === productName?.toLowerCase()
          )[0]
        )
      }
    }
  }, [subscribedProducts])

  useEffect(() => {
    if (subscribedProduct != null) {
      setIsFreeTrialSubscribed(
        subscribedProduct.package_type.toLowerCase() === AccountType.FREE &&
          subscribedProduct.product_id === product?.id
      )
      if (getItem(STRIPE_USER_CREATED) !== undefined) {
        setCurrentPricingId(subscribedProduct.price_id)
        setAccountType(subscribedProduct.package_type)
      }
    }
  }, [subscribedProduct, product])
  useEffect(() => {
    if (!productPricingLoading && !(customPackagesLoading as boolean)) {
      if (customPackages?.length > 0 && showCustomPackage) {
        setProducts([
          ...customPackages?.filter(
            (_package) =>
              _package.product_id === product?.id &&
              (_package.is_active as boolean) &&
              _package?.is_custom_package
          )
        ])
      } else if ((productPricing ?? [])?.length > 0) {
        const filteredProducts = (productPricing ?? [])?.filter(
          (prod) =>
            prod.name !== ProductStatus.ACTIVE &&
            prod.is_active &&
            !prod.is_custom_package
        )
        setProducts([...filteredProducts])
        if (currentPricingId !== undefined) {
          const currentProduct = productPricing?.find(
            (prod) => prod.id === +currentPricingId
          )
          setPackageOrder(currentProduct?.package_order as number)
        }
      } else {
        setProducts([])
      }
    }
  }, [productPricing, currentPricingId, customPackages, product])

  // useEffect(() => {
  //   if (!getItem(STRIPE_USER_CREATED)) {
  //     clearLocalStorage();
  //   }
  // }, []);

  useEffect(() => {
    setCurrentProduct(product)
    if (_showDemoVideo === 'true' && (product?.demo_video_url ?? '').length > 0) {
      showDemoVideo()
    }
  }, [product])

  useEffect(() => {
    if ((data ?? []).length > 0) {
      setProductFeatures(data)
    }
  }, [data])
  const messages = {
    button: {
      sumbit: 'Submit',
      wait: 'Wait...',
      cancel: 'Cancel',
      contactUs: 'Contact Us',
      subscribe: 'Start Free Trial',
      addToCart: 'Start For Free',
      unsubscribe: 'Unsubscribe',
      upgrade: 'Upgrade',
      downgrade: 'Downgrade',
      added: 'Added'
    }
  }

  const { mutate: addToCart } = useAddToCart()
  const {
    states: { userProfile, cart },
    actions: {
      setToaster,
      updateCart: updateCartState,
      addToCart: addToCartState
    }
  }: any = useStoreContext()

  const buttonText = (productOrder: number, productId: number): string => {
    if (productOrder === 0) {
      return messages.button.subscribe
    } else if (productOrder < (packageOrder as number)) {
      if (checkForCart(productId)) {
        return messages.button.added
      } else {
        return currentPricingId !== undefined && isFreeTrialSubscribed
          ? messages.button.addToCart
          : messages.button.downgrade
      }
    } else if (productOrder > (packageOrder as number)) {
      if (checkForCart(productId)) {
        return messages.button.added
      } else {
        return currentPricingId !== undefined && isFreeTrialSubscribed
          ? messages.button.addToCart
          : messages.button.upgrade
      }
    } else if (productId === subscribedProduct?.price_id) {
      return messages.button.unsubscribe
    } else {
      if (checkForCart(productId)) {
        return messages.button.added
      } else {
        return messages.button.addToCart
      }
    }
  }

  const handleAddToCart = (
    accountType: string,
    productPricingId: number,
    productId: number,
    packageName: string,
    amount: number
  ): void => {
    const isLoggedIn = getItem(IS_LOGGED_IN) as unknown as boolean
    removeItembyKey(FREE_TRIAL_PRODUCT)
    const res = products
      ?.filter((prod) => packages.some((pckge) => prod.name.includes(pckge)))
      ?.sort((a, b) => a?.package_order - b?.package_order)
    const obj = {
      account_type: accountType,
      product_name: productName,
      product_id: productId,
      price_id: productPricingId,
      package_name: packageName,
      amount,
      all_packages: res
    }
    if (isLoggedIn) {
      const body = { product_id: productId, price_id: productPricingId }
      addToCart(body, {
        onSuccess () {
          productAddedSuccessfully()
        }
      })
    } else {
      const res: boolean = cart.cart.some(
        (item) => item.product_id === productId
      )
      if (res) {
        updateCartState(obj)
      } else {
        addToCartState(obj)
      }
      productAddedSuccessfully()
    }
  }

  const productAddedSuccessfully = (): void => {
    setToaster({
      isDisplay: true,
      message: productAdded,
      type: ''
    })
  }

  const checkForCart = (priceId: number): boolean =>
    cart.cart.some((item) => item.price_id === priceId)

  const handleSubscribeClick = (
    accountType: string,
    productPricingId: number,
    productId: number
  ): void => {
    const product = { product_id: productId, price_id: productPricingId }
    setItembyKey(FREE_TRIAL_PRODUCT, product)
    const isLoggedIn = getItem(IS_LOGGED_IN) as unknown as boolean
    if (isLoggedIn) {
      createFreeSubscription(product as any, {
        onSuccess () {
          removeItembyKey(FREE_TRIAL_PRODUCT)
          setItem(STRIPE_USER_CREATED, true)
          navigate(APP_ROUTES.CUSTOMER_ACCOUNT)
        },
        onError () {
          setToaster({
            isDisplay: true,
            message: packageAlreadyAvailed,
            type: 'error'
          })
          removeItembyKey(FREE_TRIAL_PRODUCT)
        }
      })
    } else {
      navigate(`${APP_ROUTES.SIGNUP}`)
    }
  }

  const cardIcon = (name: string): JSX.Element => {
    switch (name) {
      case 'Free Trial':
        return <img src={StarterPkgImage} alt="Starter" />
      case 'Basic':
        return <img src={BasicPkgImage} alt="Basic" />
      case 'Standard':
        return <img src={StandardPkgImage} alt="Standard" />
      case 'Premium':
        return <img src={PremiumPkgImage} alt="Premium" />
      default:
        return <img src={PremiumPkgImage} alt="Premium" />
    }
  }

  const isProductDealex =
    productName?.toLowerCase() === Product.DEALEX.toLowerCase()

  const isProductFlex =
    productName?.toLowerCase() === Product.FLEX.toLowerCase()

  const displayFeedbackPopup = (): void => {
    showFeedbackPopup()
    hideConfimationPopup()
  }

  const [showConfimationPopup, hideConfimationPopup] = useModal(
    () => (
      <DecisionPrompt
        hide={hideConfimationPopup}
        onSave={displayFeedbackPopup}
        title={DialogHeading.UNSUBSCRIBE}
        message={unsubscribeConfirmation}
        saveBtnName={ButtonType.UNSUBSCRIBE}
      />
    ),
    []
  )

  const [showFeedbackPopup, hideFeedbackPopup] = useModal(
    () => (
      <UnsubscribeFeedbackPopup
        hide={hideFeedbackPopup}
        unsubscribe={unsubscribeProduct}
        isLoading={isLoading}
        selectedPackage={selectedPackage}
      />
    ),
    [selectedPackage, isLoading]
  )

  const [showEmailVerificationPopup, hideEmailVerificationPopup] = useModal(
    () => (
      <UnsubEmailVerificationPopup
        resendEmail={resendEmail}
        hide={hideEmailVerificationPopup}
      />
    ),
    [selectedPackage]
  )

  const unsubscribeProduct = (feedback: string): void => {
    const data = {
      feedback,
      price_id: selectedPackage?.price_id,
      verification_url: `${APP_ROUTES.UNSUBSCRIBE}`
    }
    confirmUnsubscription(data, {
      onSuccess () {
        hideFeedbackPopup()
        showEmailVerificationPopup()
      }
    })
  }

  const resendEmail = (): void => {
    const data = {
      price_id: selectedPackage?.id,
      verification_url: `${APP_ROUTES.UNSUBSCRIBE}`
    }
    confirmUnsubscription(data, {
      onSuccess () {
        hideFeedbackPopup()
        showEmailVerificationPopup()
        setToaster({
          isDisplay: true,
          message: verificationEmailSent,
          type: ''
        })
      }
    })
  }

  const unsubscribe = (selectedPackage): void => {
    setSelectedPackage(selectedPackage)
    showConfimationPopup()
  }

  const apiDataLoaded =
    !featuresLoading &&
    !testimonialsLoading &&
    !productPricingLoading &&
    !subscriptionsLoading &&
    !customPackagesLoading

  return (
    <>
      <HomePageLayout>
        {!creatingSubscritpion && product != null && productName === product.name && apiDataLoaded
          ? (
          <MainContent className="main-content" theme={theme}>
            <Container theme={theme} maxWidth="lg" className="custom-container">
              <HeroBanner
                theme={theme}
                className="hero-banner"
                mb={{ xs: 10, md: 10 }}
                overflow={'hidden'}
              >
                <Box theme={theme} className="hero-content">
                  <FadeAnimation direction='up'>
                    <Typography
                      className="prd-title"
                      theme={theme}
                      variant="h2"
                      component="h2"
                      mb={{ xs: 2, md: 3 }}
                    >
                      {currentProduct?.name ?? productName}
                    </Typography>
                    <Typography
                      theme={theme}
                      variant="h3"
                      component="h3"
                      mb={{ xs: 2, md: 3 }}
                      className="text-h3"
                    >
                      {currentProduct?.products_tag_line}
                    </Typography>
                    <Typography
                      className="text-muted text-body2"
                      theme={theme}
                      variant="body1"
                      component="p"
                      display={'block'}
                      mb={{ xs: 2, md: 3 }}
                    >
                      {currentProduct?.description}
                    </Typography>
                    { (currentProduct?.demo_video_url ?? '').length > 0
                      ? <Button
                      className={className.productDetailVideoBtn}
                      theme={theme}
                      defaultBtn
                      text={'Watch Product Demo'}
                      startIcon={
                        <img src={PlayIcon} alt="Watch Product Demo" />
                      }
                      onClick={() => {
                        Boolean(currentProduct?.demo_video_url) &&
                          showDemoVideo()
                      }}
                    />
                      : null }
                  </FadeAnimation>
                </Box>
                <Box theme={theme} className="hero-img">
                  <FadeAnimation direction='up'>
                    {currentProduct?.image_url !== null && currentProduct?.image_url !== '' && (

                        <img
                          src={currentProduct?.image_url}
                          alt=""
                          onLoad={() => {
                            setLoaded(true)
                          }}
                        />
                    )
                      }
                  </FadeAnimation>
                </Box>
              </HeroBanner>
              </Container>
              {/* LOGO Strip */}
              {
                product?.name
                  ? <ProductLogoStripComponent productName={product?.name} />
                  : null
              }
              {/* LOGO Strip */}

              {/* Features Section */}
              {(productFeatures ?? []).length > 0
                ? (
                  <Box theme={theme} pb={{ xs: 10, md: 10 }} pt={{ xs: 10, md: 10 }}>
                    <Container
                      theme={theme}
                      className="custom-container"
                      maxWidth={'lg'}
                    >
                      <ProductFeaturesComponent productFeatures={productFeatures} product={product} />
                    </Container>
                  </Box>
                  )
                : null}
              {/* Features Section */}

              {/* Why Choose Section */}
              {isProductFlex
                ? (
                  <Box theme={theme} pb={{ xs: 10, md: 10 }} pt={{ xs: 10, md: 10 }}>
                    <Container
                      theme={theme}
                      className="custom-container"
                      maxWidth={'lg'}
                    >
                      <WhyChooseProductComponent />
                    </Container>
                  </Box>
                  )
                : null}
              {/* Why Choose Section */}

              {/* Testimonials Section */}
              {+product?.id !== undefined && (TESTIMONIAL_DATA ?? [])?.length > 0
                ? (
                  <Box
                    theme={theme}
                    pb={{ xs: 10, md: 10 }}
                    pt={{ xs: 10, md: 10 }}
                  >
                    <Container
                      theme={theme}
                      className="custom-container"
                      maxWidth={'lg'}
                    >
                      <TestimonialsComponent productId={product?.id} product={product} />
                    </Container>
                  </Box>
                  )
                : null}
              {/* Testimonials Section */}

                {/* Pricing Section */}
            {
              product?.state === productState.LIVE &&
              product?.is_active && (
                <ProductPackages
                  id="pricing"
                  data-testid="Pricing"
                  theme={theme}
                  className="prd-packages-wrap"
                  pb={{ xs: 10, md: 10 }}
                  pt={{ xs: 10, md: 10 }}
                >
                  <Container
                    theme={theme}
                    maxWidth="lg"
                    className="custom-container"
                  >
                    {products?.length > 0
                      ? <FadeAnimation direction='up'>
                      <Typography
                        theme={theme}
                        textAlign="center"
                        mb={1}
                        variant="h2"
                        component="h2"
                        className="text-h1"
                      >
                        Pricing
                      </Typography>
                      <Typography
                        theme={theme}
                        textAlign="center"
                        mb={{ xs: 2, md: 2 }}
                        variant="body1"
                        component="p"
                        className="text-body2 text-muted"
                      >
                        { (products?.length === 1 && products[0]?.statue === 'FREE')
                          ? `Explore ${product?.name} with our Starter trial or contact sales team for a personalized Custom package.`
                          : 'We have several powerful plans to best fit your business needs. Choose the one which works for you!'
                        }
                      </Typography>
                        <Box theme={theme} textAlign="center">
                            <Typography
                              theme={theme}
                              display={'inline-block'}
                              mb={{ xs: 3, md: 5 }}
                              variant="caption"
                              component="small"
                              className="badge text-primary text-uppercase"
                            >
                              {'Get the first 90 days free of cost - No credit card required!'}
                            </Typography>
                      </Box>
                    </FadeAnimation>
                      : null }
                    <Grid
                      theme={theme}
                      container
                      justifyContent={'center'}
                      item
                      spacing={{ xs: 2, md: 1 }}
                    >
                      {products?.length > 0 && products
                        ?.sort((a, b) => a?.package_order - b?.package_order)
                        .map((product) => {
                          const {
                            name,
                            amount,
                            allowed_transactions,
                            additional_price_per_trans
                          } = product
                          const cardType = buttonText(
                            product.package_order,
                            product.id
                          )
                          const isProductFreeTrial =
                            product.name === 'Free Trial'
                          const introduction = isProductFreeTrial
                            ? 'Get started with Flex’s superior calculation capabilities with a limited trial version.'
                            : 'Enhance your system’s calculation power with an advance version.'
                          const subscription = subscriptions?.find(
                            (subscription) => {
                              if (subscription.price_id === product.id) {
                                return (
                                  !subscription.is_cancelled &&
                                  subscription.is_active
                                )
                              }
                              return false
                            }
                          )
                          const isPaymentMethodBankTransfer = ((subscription?.payment_type ?? '') === PaymentMethod.BANK_TRANSFER && cardType === messages.button.unsubscribe)
                          const isPackageUnsubscribed = subscriptions?.find(
                            (subscription) => {
                              if (subscription.price_id === product.id) {
                                return (
                                  subscription.is_cancelled &&
                                  subscription.is_active
                                )
                              }
                              return false
                            }
                          )?.is_cancelled ?? false
                          const isPackageInCart = checkForCart(product.id)
                          const isUserLoggedIn = (isLoggedIn() ?? '').length > 0
                          const isUserNonAdmin = isUserLoggedIn && (!(userProfile?.is_admin as boolean) ?? false)
                          const shouldDisableForFreeTrialPackage = isUserLoggedIn && isProductFreeTrial && (isFreeTrialSubscribed || Object.keys(subscribedProduct ?? {}).length > 0)

                          const disableButton =
                                isUserNonAdmin ||
                                isPaymentMethodBankTransfer ||
                                isPackageUnsubscribed ||
                                shouldDisableForFreeTrialPackage ||
                                isPackageInCart

                          return (
                            <Grid
                              theme={theme}
                              item
                              xs={12}
                              md={6}
                              lg={3}
                              key={product.id}
                            >
                              <Box
                                theme={theme}
                                className={`package-card ${
                                  product.id === subscribedProduct?.price_id
                                    ? 'subscribed'
                                    : ''
                                }`}
                                display={'flex'}
                                flexDirection={'column'}
                              >
                                <Box
                                  key={product.id}
                                  theme={theme}
                                  className="package-details"
                                  flexGrow={1}
                                >
                                  <FadeAnimation>
                                    <Box
                                      theme={theme}
                                      className="pkg-img"
                                      mb={{ xs: 2, md: 3 }}
                                    >
                                      {cardIcon(name)}
                                    </Box>
                                    <Box theme={theme} className="pkg-shout">
                                      <Typography
                                        theme={theme}
                                        variant="h4"
                                        component="h4"
                                        className="text-h4"
                                      >
                                        {isProductFreeTrial ? 'Starter' : name}
                                      </Typography>
                                      <Typography
                                        className="text-muted text-body2 prd-desc"
                                        theme={theme}
                                        variant="body2"
                                        component="span"
                                        display={'block'}
                                        mt={1}
                                      >
                                        {product.description ?? introduction}
                                      </Typography>
                                      <Typography
                                        className="text-primary text-h4"
                                        theme={theme}
                                        variant="subtitle2"
                                        component="span"
                                        display={'block'}
                                        mt={{ xs: 2, md: 3 }}
                                      >
                                        {isProductFreeTrial
                                          ? 'Free'
                                          : `$${convertToAmount(
                                              amount / 100
                                            )}/Mo.`}
                                      </Typography>
                                    </Box>
                                    <Box
                                      theme={theme}
                                      className="seperator"
                                      mt={{ xs: 2, md: 3 }}
                                      mb={{ xs: 2, md: 3 }}
                                    ></Box>
                                    <Box theme={theme} className="pkg-options">
                                      <>
                                        <ul className="list">
                                          <li>
                                            <Typography
                                              className="text-muted text-body2"
                                              theme={theme}
                                              variant="body2"
                                              component="span"
                                            >
                                              {convertToAmount(
                                                allowed_transactions
                                              )}{' '}
                                              maximum{' '}
                                              {isProductDealex
                                                ? 'quotations'
                                                : 'transactions'}
                                            </Typography>
                                          </li>
                                          {name !== 'Free Trial' && (
                                            <li>
                                              <Typography
                                                className="text-muted text-body2"
                                                theme={theme}
                                                variant="body2"
                                                component="span"
                                              >
                                                $
                                                {convertToAmountWithOutSign(
                                                  additional_price_per_trans /
                                                    100,
                                                  4
                                                )}{' '}
                                                additional per{' '}
                                                {isProductDealex
                                                  ? 'new quotation'
                                                  : 'transaction'}
                                              </Typography>
                                            </li>
                                          )}
                                          {isProductFreeTrial
                                            ? (
                                            <>
                                              <li>
                                                <Typography
                                                  className="text-muted text-body2"
                                                  theme={theme}
                                                  variant="body2"
                                                  component="span"
                                                >
                                                  7 days trial
                                                </Typography>
                                              </li>
                                              {isProductDealex
                                                ? (
                                                <li>
                                                  <Typography
                                                    className="text-muted text-body2"
                                                    theme={theme}
                                                    variant="body2"
                                                    component="span"
                                                  >
                                                    Up to 1 month data retention
                                                  </Typography>
                                                </li>
                                                  )
                                                : null}
                                            </>
                                              )
                                            : (
                                            <>
                                              {isProductDealex
                                                ? (
                                                <li>
                                                  <Typography
                                                    className="text-muted text-body2"
                                                    theme={theme}
                                                    variant="body2"
                                                    component="span"
                                                  >
                                                    Up to 12 months data
                                                    retention
                                                  </Typography>
                                                </li>
                                                  )
                                                : null}
                                              <li>
                                                <Typography
                                                  className="text-muted text-body2"
                                                  theme={theme}
                                                  variant="body2"
                                                  component="span"
                                                >
                                                  1 calendar month subscription
                                                </Typography>
                                              </li>
                                            </>
                                              )}
                                        </ul>
                                       { product?.statue !== 'FREE'
                                         ? <Typography
                                          className="text-muted"
                                          theme={theme}
                                          variant="caption"
                                          component="small"
                                          display={'block'}
                                          mt={3}
                                        >
                                          *Prices without applicable taxes
                                        </Typography>
                                         : null}
                                      </>
                                    </Box>
                                  </FadeAnimation>
                                </Box>
                                {!(
                                  isProductFreeTrial &&
                                  (isFreeTrialSubscribed ||
                                    isPaidPackageSubscribed)
                                ) && (
                                  <Box
                                    theme={theme}
                                    className="package-action"
                                    mt={2}
                                  >
                                  <FadeAnimation>
                                    <Button
                                      data-testid="add-to-cart-btn"
                                      theme={theme}
                                      fullWidth
                                        primary={isPackageUnsubscribed}
                                        secondary={!(isPackageUnsubscribed ?? false)}
                                      className={`gt-${buttonText(
                                        product.package_order,
                                        product.id
                                      )}-${product.name}`}
                                      text={cardType}
                                        disabled={disableButton}
                                      onClick={() => {
                                        cardType ===
                                        messages.button.unsubscribe
                                          ? unsubscribe(subscription)
                                          : cardType ===
                                            messages.button.contactUs
                                            ? showCustomPlan()
                                            : cardType ===
                                            messages.button.subscribe
                                              ? handleSubscribeClick(
                                                product.account_type,
                                                product.id,
                                                product.product_id
                                              )
                                              : cardType ===
                                              messages.button.addToCart &&
                                            handleAddToCart(
                                              product.account_type,
                                              product.id,
                                              product.product_id,
                                              product.name,
                                              product.amount
                                            )
                                      }}
                                    ></Button>
                                  </FadeAnimation>
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          )
                        })}
                      <Grid theme={theme} item xs={12}>
                        {Array.isArray(customPackages) && (customPackages?.length === 0 || !showCustomPackage)
                          ? (
                            <>
                              { products?.length <= 0
                                ? <Fragment>
                              <FadeAnimation>
                                  <Box theme={theme} className='custom-plan-img'>
                                    <img src={CustomPlanImage} alt='Custom Plan'/>
                                  </Box>
                                </FadeAnimation>

                                <FadeAnimation direction='down'>
                                  <Typography
                                    theme={theme}
                                    textAlign="center"
                                    mt={3}
                                    variant="h2"
                                    component="h2"
                                    className="text-h2"
                                  >
                                    Custom Plan
                                  </Typography>
                                </FadeAnimation>
                                </Fragment>
                                : null }
                            </>
                            )
                          : null}
                          <Box
                            theme={theme}
                            className="custom-plan-wrap"
                            display={'flex'}
                            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                            alignItems={'center'}
                            mt={{ xs: 3, md: 5 }}
                          >
                            <Box
                              theme={theme}
                              className="plan-content"
                              flexGrow={1}
                            >
                              <FadeAnimation>
                                <>
                                <Typography
                                  theme={theme}
                                  variant="h4"
                                  component="h4"
                                  className="text-h4"
                                  mb={1}
                                >
                                  Enterprise
                                </Typography>
                                <Typography
                                  className="text-muted text-body2"
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  {
                                    customPackageDescription[
                                      currentProduct?.name?.toLowerCase() ?? 0
                                    ]
                                  }
                                </Typography>
                                </>
                              </FadeAnimation>
                            </Box>
                            <Box
                              theme={theme}
                              className="plan-cta"
                              flexShrink={0}
                              width={{ xs: '100%', md: 'auto' }}
                              mt={{ xs: 2, md: 0 }}
                            >
                              <FadeAnimation>
                                <Button
                                  theme={theme}
                                  fullWidth
                                  className={
                                    className.productDetailContactUsBtn
                                  }
                                  secondary
                                  text={messages.button.contactUs}
                                  onClick={() => {
                                    showCustomPlan()
                                  }}
                                />
                              </FadeAnimation>
                            </Box>
                          </Box>
                          <Box
                            theme={theme}
                            className="reach-us"
                            mt={3}
                            textAlign={'center'}
                          >
                            <FadeAnimation direction='down'>
                                <Typography
                                  className="text-muted text-body2"
                                  mt={1}
                                  theme={theme}
                                  variant="body2"
                                  component="span"
                                  display={'block'}
                                >
                                  Don’t see a plan that works for you?{' '}
                                  <a
                                    href="javascript:void(0)"
                                    className="link text-primary"
                                    onClick={() => {
                                      navigate(APP_ROUTES.CONTACT_US)
                                    }}
                                  >
                                    Reach us out
                                  </a>{' '}
                                  and we’ll work to find a solution for you.
                                </Typography>
                              </FadeAnimation>
                          </Box>
                        </Grid>
                    </Grid>
                  </Container>
                  <Box
                    theme={theme}
                    component="section"
                    className="product-features"
                    display={'none'}
                  >
                    <Container
                      theme={theme}
                      maxWidth="lg"
                      className="custom-container"
                    >
                      <Typography
                        variant="h2"
                        component="h2"
                        className="text-h2"
                        theme={theme}
                        textAlign={'center'}
                        mb={5}
                      >
                        Features
                      </Typography>
                      <Grid
                        theme={theme}
                        container
                        item
                        spacing={5}
                        alignItems={'stretch'}
                        justifyContent={'center'}
                      >
                        {currentProduct !== undefined &&
                          currentProduct?.features?.length > 0 &&
                          currentProduct?.features.map((feature, index) => (
                            <Feature
                              key={feature.name}
                              feature={feature}
                              index={index}
                            />
                          ))}
                        <Grid theme={theme} item xs={12}>
                          <Typography
                            theme={theme}
                            className="text-muted fw-reg"
                            variant="h4"
                            component="h4"
                            textAlign={'center'}
                          >
                            Pricing and more details are coming soon.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </ProductPackages>
              )}

            {(product?.state !== productState.LIVE) &&
              product?.is_active && (
                <Box
                  theme={theme}
                  pb={{ xs: 10, md: 10 }}
                  pt={{ xs: 10, md: 10 }}
                >
                  <Container
                    theme={theme}
                    className="custom-container"
                    maxWidth={'lg'}
                  >
                    <JoinWaitList productId={product?.id} />
                  </Container>
                </Box>
            )}
              {/* Pricing Section */}

              {/* How to Connect Section */}
              {product?.state === productState.LIVE
                ? (
                  <Box theme={theme}>
                    <HowToConnectComponent product={product} />
                  </Box>
                  )
                : null}
              {/* How to Connect Section */}

              {/* Support Section */}
            { product?.state === productState.LIVE && (
              <SupportWrap
                theme={theme}
                className="support-wrap"
                pb={{ xs: 10, md: 10 }}
                pt={{ xs: 10, md: 10 }}
              >
                <Container
                  theme={theme}
                  className="custom-container"
                  maxWidth={'lg'}
                >
                  <Box
                    theme={theme}
                    className="section-head"
                    textAlign={'center'}
                    maxWidth={996}
                    margin={'auto'}
                  >
                    <FadeAnimation direction='up'>
                      <Typography
                        display={'inline-block'}
                        mb={{ xs: 2, md: 3 }}
                        theme={theme}
                        className="badge text-primary text-uppercase"
                        variant="caption"
                        component="small"
                      >
                        SUPPORT
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="h2"
                        component="h2"
                        className="text-h2"
                      >
                        Have questions that need answers?
                      </Typography>
                      <Typography
                        theme={theme}
                        className="text-muted text-body2"
                        variant="body1"
                        component="p"
                        mt={{ xs: 2, md: 3 }}
                      >
                        In case of any query, visit our extensive documentation and FAQs, or simply contact us at  <a
                          className="text-primary link"
                          href={`mailto:${process.env.SUPPORT_EMAIL as string}`}
                        >
                          {process.env.SUPPORT_EMAIL}
                        </a>
                      </Typography>
                    </FadeAnimation>
                  </Box>
                  <Box theme={theme} className="sec-content" mt={6}>
                    <Grid
                      theme={theme}
                      container
                      item
                      spacing={3}
                      alignItems="stretch"
                      justifyContent={'center'}
                    >
                      <Grid theme={theme} item xs={12} md={6} lg={5}>
                        <Box theme={theme} className="support-box api-docs">
                          <FadeAnimation>
                            <Typography
                              theme={theme}
                              variant="h3"
                              component="h3"
                              className="text-h3"
                            >
                              API Documentation
                            </Typography>
                            <Typography
                              theme={theme}
                              mt={1}
                              mb={2}
                              variant="body2"
                              component="span"
                              className="text-muted text-body2"
                              display={'block'}
                            >
                              Discover and learn how to connect and integrate
                              with your own tools or what you can get out of our
                              documentation.
                            </Typography>
                            {
                              (() => {
                                const button = (
                                  <Button
                                    theme={theme}
                                    defaultBtn
                                    className={
                                      className.productDetailViewDocBtn
                                    }
                                    text="View Documentation"
                                    onClick={() =>
                                      Boolean(product?.documentation_url) &&
                                      openInNextWindow(
                                        `${
                                          process.env.DOCUMENTATION as string
                                        }${product?.documentation_url}`
                                      )
                                    }
                                    endIcon={<Icon name="IconLeftArrow" />}
                                  ></Button>
                                )

                                if ((product?.documentation_url ?? '').length > 0) {
                                  return button
                                } else {
                                  return (<Tooltip
                                  theme={theme}
                                  title="Coming soon"
                                  placement="bottom-start"
                                >
                                  {button}
                                </Tooltip>)
                                }
                              })()
                            }
                            <Box theme={theme} className="box-img" mt={5}>
                              <img src={ApiDocsImage} />
                            </Box>
                          </FadeAnimation>
                        </Box>
                      </Grid>
                      <Grid item theme={theme} xs={12} md={6} lg={5}>
                        <Box theme={theme} className="support-box">
                          <FadeAnimation>
                            <Typography
                              theme={theme}
                              variant="h3"
                              component="h3"
                              className="text-h3"
                            >
                              Marketing
                            </Typography>
                            <Typography
                              theme={theme}
                              mt={1}
                              mb={2}
                              variant="body2"
                              component="span"
                              className="text-muted text-body2"
                              display={'block'}
                            >
                              If you are a publication or want to feature this
                              product somewhere, here’s our PR pack.
                            </Typography>
                            {(() => {
                              const button = (
                                <Button
                                theme={theme}
                                defaultBtn
                                text="Download PR Pack"
                                className={
                                  className.productDetailDownloadPRPackBtn
                                }
                                onClick={() =>
                                  Boolean(product?.pr_pack_url) &&
                                  openInNextWindow(product?.pr_pack_url)
                                }
                                endIcon={<Icon name="SaveIcon" />}
                              ></Button>
                              )

                              if ((product?.pr_pack_url ?? '').length > 0) {
                                return button
                              } else {
                                return (<Tooltip
                                  theme={theme}
                                  title="Coming soon"
                                  placement="bottom-start"
                                >
                                  {button}
                                </Tooltip>)
                              }
                            })()
                            }
                            <Box theme={theme} className="box-img" mt={5}>
                              <img src={MarketingImage} />
                            </Box>
                          </FadeAnimation>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </SupportWrap>
            )}

              {/* Support Section */}

              {/* FAQ Section */}
            <Box theme={theme} pb={{ xs: 10, md: 10 }} pt={{ xs: 10, md: 10 }}>
              <Container
                theme={theme}
                className="custom-container"
                maxWidth={'lg'}
              >
                <FaqsComponent productId={product?.id} />
              </Container>
              </Box>
              {/* FAQ Section */}

              {/* Blog Section */}
            {blog && <Box theme={theme} pb={{ xs: 10, md: 10 }} pt={{ xs: 10, md: 10 }}>
              <Container
                theme={theme}
                className="custom-container"
                maxWidth={'lg'}
              >
                <NavLink
                  to={`${APP_ROUTES.BLOGS}/${blog.title_url}`}
                  style={{ textDecoration: 'none' }}
                >
                  <BlogItemComponent
                    blog={blog}
                    onClick={() => { navigate(`${APP_ROUTES.BLOGS}/${blog.title_url}`) }}/>
                </NavLink>
              </Container>
              </Box>}

              {/* Blog Section */}

              {/* News Letter Section */}
              <NewsLetterComponent />
              {/* News Letter Section */}
          </MainContent>
            )
          : (
          <FullPageLoader theme={theme} className="full-page-loader">
            <CircleLoader theme={theme} />
          </FullPageLoader>
            )}
      </HomePageLayout>
    </>
  )
}
