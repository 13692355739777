import { type FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { type IProductResponse } from '@shared/typings'
import {
  ButtonType,
  className,
  dateFormat,
  DialogHeading,
  PaymentMethod,
  ProductActions,
  unsubscribeConfirmation,
  verificationEmailSent
} from '@shared/constants'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { useStoreContext } from 'store/storeContext'
import { messages } from '../pages/customerAccount/customerAccountPage'
import { ProductCard, ProductWrap } from './productStyle'
import {
  formatedDate,
  convertToAmount,
  AccountType,
  hasNoPlayground,
  openInNextWindow,
  getNextScreenUrl
} from 'shared'
import {
  Box,
  Typography,
  Grid,
  Button,
  TooltipProgressBar,
  Menu,
  Icon
} from '@ntpkunity/controls'
import EmptyState from './emptyState'
import {
  UnsubscribeFeedbackPopup,
  UnsubEmailVerificationPopup,
  useConfirmUnsubscription
} from '@modules/unsubscription'
import { useModal } from 'react-modal-hook'
import { type IMenuItemOptions } from '@ntpkunity/controls/dist/cjs/components/Menu/Menu.types'
import { DecisionPrompt } from '@shared/components/DecisionPrompt/decisionPrompt'

interface ISubscribedProductsProps {
  products: IProductResponse[] | undefined
}

export const SubscribedProducts: FC<ISubscribedProductsProps> = ({
  products
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [subscribedProducts, setSubscribedProducts] = useState(products)
  const [selectedPackage, setSelectedPackage] = useState<IProductResponse>()
  const { mutate: confirmUnsubscription, isLoading } =
    useConfirmUnsubscription()

  const {
    actions: { setToaster }
  } = useStoreContext()

  const menuOptions = (product: IProductResponse): IMenuItemOptions[] => {
    const options: IMenuItemOptions[] = []

    const { product_path: productPath, product_name: productName, documentation_url: documentationUrl, user_manual_url: UserManual, is_cancelled: isCancelled } = product
    if ((productPath ?? '').length > 0) {
      options.push({
        optionText: hasNoPlayground(productName)
          ? messages.button.gotoApp
          : messages.button.playground,
        className: hasNoPlayground(productName)
          ? className.ellipsisGoToAppOption
          : className.ellipsisPlaygroundOption,
        optionkey: 'Playground'
      })
    }
    if ((documentationUrl ?? '').length > 0) {
      options.push(
        {
          className: className.ellipsisDocumentationOption,
          optionText: 'API Documentation',
          optionkey: 'API Documentation'
        }
      )
    }
    if ((UserManual ?? '').length > 0) {
      options.push(
        {
          className: className.ellipsisUserManualOption,
          optionText: 'User Manual',
          optionkey: 'User Manual'
        }
      )
    }
    if (!isCancelled) {
      options.push(
        {
          className: className.ellipsisUnsubscribe,
          optionText: 'Unsubscribe',
          optionkey: 'Unsubscribe',
          disabled: (product?.payment_type ?? '') === PaymentMethod.BANK_TRANSFER
        }
      )
    }

    return options
  }

  const handleSelection = (key, selectedPackage: IProductResponse): void => {
    switch (key) {
      case ProductActions.UNSUBSCRIBE:
        setSelectedPackage(selectedPackage)
        showConfimationPopup()
        break
      case ProductActions.PLAYGROUND:
        openInNextWindow(`${getNextScreenUrl()}/${selectedPackage.product_path}`)
        break
      case ProductActions.GO_TO_APP:
        openInNextWindow(`${getNextScreenUrl()}/${selectedPackage.product_path}`)
        break
      case ProductActions.DOCUMENTATION_URL:
        openInNextWindow(`${process.env.DOCUMENTATION as string}${selectedPackage.documentation_url}`)
        break
      case ProductActions.USER_MANUAL:
        openInNextWindow(`${process.env.USER_MANUAL_URL as string}${selectedPackage.user_manual_url}`)
        break
    }
  }

  // const {
  //   states: { pusherChannel },
  // } = useStoreContext();

  // useEffect(() => {
  //   if (pusherChannel.pusherChannel) {
  //     pusherChannel.pusherChannel.bind(CALCULATION_PERFORMED, (data) => {
  //       if (data.is_success) {
  //         setSubscribedProducts(
  //           products?.map((product) => {
  //             if (product.price_id === data.price_id) {
  //               product.transactions_performed = data.transactions_performed
  //                 ? data.transactions_performed
  //                 : product.transactions_performed + 1;
  //               product.remaining_transactions = data.remaining_transactions
  //                 ? data.remaining_transactions
  //                 : product.remaining_transactions - 1;
  //             }
  //             return product;
  //           })
  //         );
  //       }
  //     });
  //   }
  // }, [pusherChannel]);

  useEffect(() => {
    if ((products ?? []).length > 0) setSubscribedProducts(products)
  }, [products])

  const displayFeedbackPopup = (): void => {
    showFeedbackPopup()
    hideConfimationPopup()
  }

  const [showConfimationPopup, hideConfimationPopup] = useModal(
    () => (
      <DecisionPrompt
        hide={hideConfimationPopup}
        onSave={displayFeedbackPopup}
        title={DialogHeading.UNSUBSCRIBE}
        message={unsubscribeConfirmation}
        saveBtnName={ButtonType.UNSUBSCRIBE}
      />
    ),
    []
  )

  const [showFeedbackPopup, hideFeedbackPopup] = useModal(
    () => (
      <UnsubscribeFeedbackPopup
        hide={hideFeedbackPopup}
        unsubscribe={unsubscribeProduct}
        isLoading={isLoading}
        selectedPackage={selectedPackage}
      />
    ),
    [selectedPackage, isLoading]
  )

  const [showEmailVerificationPopup, hideEmailVerificationPopup] = useModal(
    () => <UnsubEmailVerificationPopup resendEmail={resendEmail} hide={hideEmailVerificationPopup} />,
    [selectedPackage]
  )

  const unsubscribeProduct = (feedback: string): void => {
    const data = {
      feedback,
      price_id: selectedPackage?.price_id,
      verification_url: `${APP_ROUTES.UNSUBSCRIBE}`
    }
    confirmUnsubscription(data, {
      onSuccess () {
        hideFeedbackPopup()
        showEmailVerificationPopup()
      }
    })
  }

  const resendEmail = (): void => {
    const data = {
      price_id: selectedPackage?.price_id,
      verification_url: `${APP_ROUTES.UNSUBSCRIBE}`
    }
    confirmUnsubscription(data, {
      onSuccess () {
        hideFeedbackPopup()
        showEmailVerificationPopup()
        setToaster({
          isDisplay: true,
          message: verificationEmailSent,
          type: ''
        })
      }
    })
  }

  return (
    <ProductWrap theme={theme} className="products-wrap">
      <Grid theme={theme} container item spacing={4} pb={{ md: 10, sm: 6 }}>
        {(subscribedProducts ?? [])?.length > 0
          ? (
              subscribedProducts?.map((product) => {
                const excessTransactions =
              product.transactions_performed - product.allowed_transactions
                const excessTransactionPercentage = Math.min(
                  (excessTransactions / product.allowed_transactions) * 100,
                  100
                )
                const transactionsPercentage =
              (product.remaining_transactions / product.allowed_transactions) *
              100
                return (
              <Grid
                key={product.id}
                theme={theme}
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
              >
                <ProductCard
                  theme={theme}
                  className="product-card"
                  mt={1}
                  mb={3}
                >
                  <Box theme={theme} className="prd-img" mb={3}>
                    <img src={product.image_url} alt="" />
                    {/* <Box theme={theme} className="prd-overlay-content">
                      <Button
                        theme={theme}
                        fullWidth
                        secondary
                        text={messages.button.documentation}
                        onClick={() =>
                          openInNextWindow(
                            `${process.env.DOCUMENTATION}${product.documentation_url}`
                          )
                        }
                      ></Button>
                      {product?.product_path ? (
                        <Button
                          theme={theme}
                          fullWidth
                          secondary
                          text={
                            productHasNoPlayground
                              ? messages.button.gotoApp
                              : messages.button.playground
                          }
                          onClick={() =>
                            openInNextWindow(
                              `${process.env.NEXT_SCREEN_BASE_URL}/${product?.product_path}`
                            )
                          }
                        ></Button>
                      ) : null}
                    </Box> */}
                  </Box>
                  <Box theme={theme} className="prd-content-wrap">
                    <Typography theme={theme} variant="h4" component="h4">
                      {product.product_name}
                    </Typography>
                    <Box theme={theme} className="prod-details">
                      <Box theme={theme} className="item" mt={1}>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="caption"
                          component="small"
                        >
                          {messages.label.package}
                        </Typography>
                        <Typography
                          className="text-muted"
                          ml={1}
                          theme={theme}
                          variant="caption"
                          component="small"
                        >
                          {product.status}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="item" mt={1}>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="caption"
                          component="small"
                        >
                          {product.is_cancelled ? messages.label.unsubscriptionEffectiveDate : messages.label.dueDate}
                        </Typography>
                        <Typography
                          className="text-muted"
                          ml={1}
                          theme={theme}
                          variant="caption"
                          component="small"
                        >
                          {formatedDate(product.is_cancelled ? product.cancellation_date : product.billing_cycle_end_date, dateFormat)}
                        </Typography>
                      </Box>
                      {excessTransactions <= 0 && (
                        <Box theme={theme} className="item" mt={1}>
                          <Typography
                            className="text-muted"
                            theme={theme}
                            variant="caption"
                            component="small"
                          >
                            {messages.label.transactions}
                          </Typography>
                          <Typography
                            className="text-muted"
                            ml={1}
                            theme={theme}
                            variant="caption"
                            component="small"
                          >
                            {convertToAmount(product.allowed_transactions)}
                          </Typography>
                        </Box>
                      )}
                      {excessTransactions > 0 && (
                        <Box theme={theme} className="item" mt={1}>
                          <Typography
                            className={`${excessTransactions > 0 ? 'text-danger' : ''}`}
                            theme={theme}
                            variant="caption"
                            component="small"
                          >
                            {messages.label.excessTransactions}
                          </Typography>
                          <Typography
                            className={`${excessTransactions > 0 ? 'text-danger' : ''}`}
                            ml={1}
                            theme={theme}
                            variant="caption"
                            component="small"
                          >
                            {convertToAmount(excessTransactions)}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        theme={theme}
                        className={`progress-wrap ${excessTransactions > 0 ? 'progress-danger' : ''}`}
                        mt={1}
                      >
                        <TooltipProgressBar
                          theme={theme}
                          value={
                            excessTransactions > 0
                              ? excessTransactionPercentage
                              : transactionsPercentage
                          }
                          title={
                            convertToAmount(
                              excessTransactions > 0
                                ? excessTransactions
                                : product.remaining_transactions
                            ) as any
                          }
                        />
                      </Box>
                      <Box theme={theme} mt={3} className={`prd-action ${product.is_cancelled ? 'is-unsubscribed' : ''}`} >
                        <Button
                          fullWidth
                          className={`${className.subscribedProductsUpgradeBtn}-${product.product_name}`}
                          theme={theme}
                          primary
                          disabled={product.is_cancelled}
                          onClick={() => {
                            navigate(
                              `${APP_ROUTES.DETAIL_PAGE}/${product.product_name}`,
                              {
                                state: {
                                  accountType: AccountType.PAID,
                                  productPricingId: product.price_id
                                }
                              }
                            )
                          }
                          }
                          text={messages.button.upgrade}
                        ></Button>
                        <Menu theme={theme}
                          disablePortal={true}
                          options={menuOptions(product)}
                          handleOptionClick={(e) => { handleSelection(e.target.innerText, product) }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          render={cb => <Button theme={theme} secondary iconText={<Icon name="MoreIcon" />} onClick={cb} />}
                        />
                      </Box>
                    </Box>
                  </Box>
                </ProductCard>
              </Grid>
                )
              })
            )
          : (
          <EmptyState isTrialTab={false} />
            )}
      </Grid>
    </ProductWrap >
  )
}
