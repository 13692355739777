import { useEffect, useState, type FC } from 'react'
import { Divider, IconButton } from '@mui/material'
import { type IAppBarProps } from './header.types'
import Toolbar from '@mui/material/Toolbar'
import clsx from 'clsx'
import {
  Icon,
  Box,
  Container,
  Menu,
  Typography,
  Avatar,
  List,
  Autocomplete
} from '@ntpkunity/controls'
import { APP_ROUTES } from '@router/path'
import { useLocation, useNavigate, NavLink } from 'react-router-dom'
import LogoImage from '@shared/assets/images/appex-now-logo.svg'
import EmptyCartImage from '@shared/assets/images/empty-cart-img.svg'
import {
  getInitials,
  getItemByKey,
  HeaderOption,
  openInNextWindow,
  hasNoPlayground,
  setItembyKey,
  isLoggedIn,
  logoutMarketplace,
  isUserOnBoarded,
  getNextScreenUrl
} from 'shared'
import {
  CART_DATA,
  Category,
  className,
  IS_CHECKOUT,
  packages,
  TERMLY_API_CACHE
} from '@shared/constants'
import {
  CustomAppBar,
  HeaderSearchWrap,
  ResponsiveHeaderMenu
} from './headerStyle'
import { useStoreContext } from 'store/storeContext'
import { setFavicon } from '../../../favicon'
import { logout } from '@ntpkunity/controls-ums'
import CartIcon from '@shared/assets/images/ic-bag.svg'
import { useGetAllCarts } from '@modules/product/services/cart/cartService'
import { useGetProductPricing } from '@modules/product'
import { CheckoutItemList } from '@shared/components/Cart/CheckoutItemList'
import { useGetUserProducts } from '@modules/authentication'
import { messages } from '@modules/customerAccount/pages/customerAccount/customerAccountPage'
import { type ISubOptions } from '@shared/typings'
import { NotificationComponent } from './notifications'
import { useGetAllNotifications } from './notifications/services/NotificationService'

const ResponsiveAppBar: FC<IAppBarProps> = ({ theme }) => {
  const { pathname } = useLocation()
  const _isLoggedIn = (isLoggedIn() ?? '').length > 0
  const navigate = useNavigate()
  const [scroll, setScroll] = useState(false)
  const [openMenu, setOpenMenu] = useState(true)
  const [openSearch, setOpenSearch] = useState(true)
  const { data: carts, isLoading } = useGetAllCarts(_isLoggedIn)
  useGetAllNotifications()
  const { data: allPricing, isLoading: isPricingLoading } =
    useGetProductPricing()
  const { data: userProducts } = useGetUserProducts()

  const {
    states: { userProfile, cart },
    actions: { addMultipleItemsToCart }
  }: any = useStoreContext()

  const isUserOnBoardedAndAdmin = isUserOnBoarded() && (userProfile?.is_admin as boolean ?? false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 80)
    })
  }, [])

  useEffect(() => {
    const data = getItemByKey(CART_DATA)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (data) {
      addMultipleItemsToCart(data.cartlist)
      return
    }
    if (_isLoggedIn && !isLoading && !isPricingLoading) {
      const arr: any[] = []
      carts?.forEach((item: any) => {
        const {
          id,
          account_type: accountType,
          product_name: productName,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          product_id,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          price_id,
          name: packageName,
          amount
        } = item
        const res = allPricing
          ?.filter(
            (prod) =>
              prod.product_id === item.product_id &&
              packages.includes(prod.name)
          )
          ?.sort((a, b) => a?.package_order - b?.package_order)
        const obj = {
          id,
          price_id,
          amount,
          product_id,
          account_type: accountType,
          product_name: productName,
          package_name: packageName,
          all_packages: res
        }
        arr.push(obj)
      })
      addMultipleItemsToCart(arr)
    }
  }, [carts, allPricing])

  const handleSelection = (value, key, test): void => {
    switch (key) {
      case HeaderOption.LOGIN:
        navigate(APP_ROUTES.LOGIN)
        break
      case HeaderOption.CREATE_ACCOUNT:
        navigate(APP_ROUTES.SIGNUP)
        break
      case HeaderOption.CHANGE_PASSWORD:
        navigate(APP_ROUTES.CHANGE_PASSWORD)
        break
      case HeaderOption.MY_PROFILE:
        navigate(APP_ROUTES.CUSTOMER_ACCOUNT)
        break
      case HeaderOption.LOGOUT: {
        logoutMarketplace()
        break
      }
    }
  }

  setFavicon()

  const subOptions = (
    productPath: string,
    documentationUrl: string,
    productName: string
  ): ISubOptions[] => {
    const options: ISubOptions[] = []
    if ((productPath ?? '').length > 0) {
      options.push({
        label: hasNoPlayground(productName)
          ? messages.button.gotoApp
          : messages.button.playground,
        onClick: () => {
          openInNextWindow(
            `${getNextScreenUrl()}/${productPath}`
          )
        }
      })
    }
    if ((documentationUrl ?? '').length > 0) {
      options.push({
        label: 'View Documentation',
        onClick: () => {
          openInNextWindow(
            `${process.env.DOCUMENTATION as string}${documentationUrl}`
          )
        }
      })
    }
    return options
  }

  const userSubscribedProducts = (): any => {
    return userProducts?.map((product) => {
      return {
        icon: (
          <>
            <img src={product.icon_url} alt={product.name} />
          </>
        ),
        label: product.name,
        hideLabel: false,
        hideIcon: false,
        // onClick: () => {},
        subOptions: subOptions(
          product.product_path,
          product.documentation_url,
          product.name
        )
      }
    })
  }

  const MenuComp = (): JSX.Element => {
    return (
      <li
        className={`nav-item profile-link dropdown ${
          _isLoggedIn ? 'logged-in-dropdown' : ''
        }`}
      >
        <Menu
          theme={theme}
          disablePortal={true}
          customChildren={
            <>
              {_isLoggedIn && userProducts?.length > 0 && (
                <>
                  <Box theme={theme} className="drodown-menu-title">
                    <Typography
                      theme={theme}
                      className="text-muted text-uppercase"
                      component="small"
                      variant="caption"
                    >
                      subscribed apps
                    </Typography>
                  </Box>
                  <Box theme={theme} className="custom-list-wrap">
                    <List
                      theme={theme}
                      shouldCollapse={true}
                      options={userSubscribedProducts()}
                    />
                    {isUserOnBoardedAndAdmin ? <Divider /> : null}
                  </Box>
                </>
              )}
            </>
          }
          handleOptionClick={handleSelection}
          options={_isLoggedIn ? loggedInOptions : loggedOutOptions}
          render={(cb) => (
            <>
              <a className="nav-link" href="javascript:void(0)" onClick={cb}>
                {(userProfile?.first_name ?? '').length > 0 &&
                pathname !== APP_ROUTES.SIGNUP
                  ? (
                  <Avatar theme={theme}>
                    {getInitials(
                      `${userProfile?.first_name ?? ''}`,
                      `${userProfile?.last_name ?? ''}`
                    )}

                  </Avatar>
                    )
                  : (
                  <Icon name="PersonIcon" />
                    )}
              </a>
            </>
          )}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </li>
    )
  }

  const searchItems = [
    {
      label: 'Lorem ipsum dolor sit amet',
      id: 1
    },
    {
      label: 'Lorem ipsum dolor sit amet consectetur',
      id: 2
    },
    {
      label: 'Lorem ipsum dolor sit amet consectetur nunc nunc lectus',
      id: 3
    },
    {
      label: 'Lorem ipsum dolor',
      id: 4
    },
    {
      label: 'Lorem ipsum dolor sit amet consectetur nunc',
      id: 5
    }
  ]

  function handleCheckout (): void {
    if (_isLoggedIn) {
      navigate(APP_ROUTES.CHECKOUT)
    } else {
      navigate(APP_ROUTES.SIGNUP)
      setItembyKey(IS_CHECKOUT, true)
    }
  }

  const loggedInOptions = [
    ...(isUserOnBoardedAndAdmin ? [{ optionText: 'My Profile', optionkey: 'My Profile' }] : []),
    { optionText: 'Logout', optionkey: 'Logout' }
  ]

  const loggedOutOptions = [
    { optionText: 'Login', optionkey: 'Login' },
    { optionText: 'Create Account', optionkey: 'Create Account' }
  ]

  return (
    <CustomAppBar
      className={clsx('u-custom-app-header', !scroll ? '' : 'top-nav')}
      theme={theme}
    >
      <Container theme={theme} maxWidth="lg" className="custom-container">
        <Toolbar disableGutters className="u-toolbar">
          <Box className="app-logo" theme={theme} sx={{ flexGrow: { xs: 1 } }}>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate(APP_ROUTES.NEW_HOME_PAGE)
              }}
            >
              <img src={LogoImage} alt="Logo" />
            </a>
          </Box>
          <Box
            theme={theme}
            sx={{ flexGrow: { xs: 0 }, display: { xs: 'flex', md: 'none' } }}
          >
            <IconButton
              className={clsx('toggle-nav', openMenu ? '' : 'change')}
              disableRipple={true}
              disableTouchRipple={true}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {
                setOpenMenu(!openMenu)
              }}
            >
              <Box theme={theme} className="menu-icon">
                <Box theme={theme} className="toggle-bar"></Box>
              </Box>
            </IconButton>
          </Box>
          <Box
            theme={theme}
            className={clsx('header-nav-wrap', openMenu ? '' : 'menu-open')}
          >
            <ul className="navbar-nav nav-center">
              <li className="nav-item">
                <NavLink
                  className={`nav-link click-close ${className.headerHomeBtn}`}
                  to={`${APP_ROUTES.NEW_HOME_PAGE}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography theme={theme} variant="body2" component="span">
                    Home
                  </Typography>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link click-close ${className.headerProductsBtn}`}
                  to={`${APP_ROUTES.PRODUCTS}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography theme={theme} variant="body2" component="span">
                    Products
                  </Typography>
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="javascript:void(0)">
                  <Typography theme={theme} variant="body2" component="span">
                    Services
                  </Typography>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="javascript:void(0)">
                  <Typography theme={theme} variant="body2" component="span">
                    Add-Ons
                  </Typography>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="javascript:void(0)">
                  <Typography theme={theme} variant="body2" component="span">
                    Bundles
                  </Typography>
                </a>
              </li> */}
              <li className="nav-item">
                <NavLink
                  className={`nav-link click-close ${className.headerBlogBtn}`}
                  to={`${APP_ROUTES.BLOGS}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography theme={theme} variant="body2" component="span">
                    Blog
                  </Typography>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link click-close ${className.headerContactUsBtn}`}
                  to={`${APP_ROUTES.CONTACT_US}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography theme={theme} variant="body2" component="span">
                    Contact Us
                  </Typography>
                </NavLink>
              </li>
            </ul>
            <ul className="navbar-nav nav-right">
              {/* <li className="nav-item icon-item">
                <a className="nav-link" href="javascript:void(0)" onClick={() => setOpenSearch(!openSearch)}>
                  <Icon name="SearchIcon" />
                </a>
              </li> */}
              {
                isUserOnBoardedAndAdmin
                  ? (
                    <li className="nav-item icon-item dropdown notification-dropdown cart-dropdown">
                      <NotificationComponent theme={theme} key='normal' />
                    </li>
                    )
                  : null
              }
              {
                !isLoggedIn() || isUserOnBoardedAndAdmin
                  ? (
                    <li className="nav-item icon-item dropdown cart-dropdown">
                      <Menu
                        theme={theme}
                        disablePortal={true}
                        customChildren={
                          <>
                            <Box
                              theme={theme}
                              className="cart-dropdown-wrap"
                              p={3}
                              maxWidth={300}
                            >
                              {cart?.cart?.length === 0
                                ? (
                                  <Box
                                    theme={theme}
                                    className="empty-cart"
                                    textAlign={'center'}
                                  >
                                    <Box theme={theme} className="empty-cart-img">
                                      <img
                                        src={EmptyCartImage}
                                        alt="Your cart is lonely!"
                                      />
                                    </Box>
                                    <Box
                                      theme={theme}
                                      className="empty-cart-message"
                                      mt={3}
                                    >
                                      <Typography
                                        className="text-dark"
                                        theme={theme}
                                        variant="subtitle1"
                                        component="p"
                                      >
                                        Your cart is lonely!
                                      </Typography>
                                      <Typography
                                        className="text-muted"
                                        theme={theme}
                                        variant="caption"
                                        component="small"
                                      >
                                        Looks like you have not added anything in your
                                        cart yet. Search for items to start adding them
                                        here.
                                      </Typography>
                                    </Box>
                                  </Box>
                                  )
                                : (
                                  <>
                                    <CheckoutItemList
                                      cart={cart?.cart}
                                      className={className.proceedToCheckout}
                                      buttonText={'Start For Free'}
                                      onClickHandle={handleCheckout}
                                      buttonDisplay
                                    />
                                  </>
                                  )}
                            </Box>
                          </>
                        }
                        options={[]}
                        render={(cb) => (
                          <>
                            <a
                              className="nav-link"
                              href="javascript:void(0)"
                              onClick={cb}
                            >
                              <img src={CartIcon} alt="Cart" />
                              {cart?.cart?.length > 0 && (
                                <span className="cart-counter">
                                  {cart?.cart?.length}
                                </span>
                              )}
                            </a>
                          </>
                        )}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      />
                    </li>
                    )
                  : null
              }
              <MenuComp />
            </ul>
          </Box>
        </Toolbar>
      </Container>
      <HeaderSearchWrap
        theme={theme}
        className={clsx('header-search-wrap', openSearch ? '' : 'search-open')}
        display={'none'}
      >
        <Container theme={theme} maxWidth="lg" className="custom-container">
          <Toolbar disableGutters className="u-toolbar">
            <Box
              className="app-logo"
              theme={theme}
              sx={{ flexGrow: { xs: 1 } }}
              display={{ xs: 'none', md: 'block' }}
            >
              <a>
                <img src={LogoImage} alt="Logo" />
              </a>
            </Box>
            <Box theme={theme} className="header-nav-wrap">
              <ul className="navbar-nav nav-center">
                <li className="nav-item">
                  <a className="nav-link active" href="javascript:void(0)">
                    <Typography theme={theme} variant="body2" component="span">
                      Products
                    </Typography>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)">
                    <Typography theme={theme} variant="body2" component="span">
                      Services
                    </Typography>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)">
                    <Typography theme={theme} variant="body2" component="span">
                      Add-Ons
                    </Typography>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)">
                    <Typography theme={theme} variant="body2" component="span">
                      Bundles
                    </Typography>
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav nav-right">
                <li className="nav-item icon-item">
                  <a
                    className="nav-link close-search-btn"
                    href="javascript:void(0)"
                    onClick={() => {
                      setOpenSearch(!openSearch)
                    }}
                  >
                    <Icon name="CloseBlack" />
                  </a>
                </li>
              </ul>
            </Box>
          </Toolbar>
          <Box theme={theme} className="search-area">
            <Autocomplete
              theme={theme}
              items={searchItems}
              disablePortal={true}
              endAdornment={<Icon name="SearchIcon" />}
              placeholder="Search by product name or a keyword..."
            />
          </Box>
        </Container>
      </HeaderSearchWrap>

      <ResponsiveHeaderMenu theme={theme} className="responsive-header-menu">
        <ul className="navbar-nav">
          {/* <li className="nav-item icon-item">
            <a className="nav-link" href="javascript:void(0)" onClick={() => setOpenSearch(!openSearch)}>
              <Icon name="SearchIcon" />
            </a>
          </li> */}
          {
            isUserOnBoardedAndAdmin
              ? (
                <li className="nav-item icon-item dropdown notification-dropdown cart-dropdown">
                  <NotificationComponent theme={theme} key='responsive' />
                </li>
                )
              : null
          }
          {
            !isLoggedIn() || isUserOnBoardedAndAdmin
              ? (
                <li className="nav-item icon-item dropdown cart-dropdown">
                  <Menu
                    theme={theme}
                    disablePortal={true}
                    customChildren={
                      <>
                        <Box
                          theme={theme}
                          className="cart-dropdown-wrap"
                          p={3}
                          maxWidth={'100%'}
                        >
                          {cart?.cart?.length === 0
                            ? (
                              <Box
                                theme={theme}
                                className="empty-cart"
                                textAlign={'center'}
                              >
                                <Box theme={theme} className="empty-cart-img">
                                  <img
                                    src={EmptyCartImage}
                                    alt="Your cart is lonely!"
                                  />
                                </Box>
                                <Box
                                  theme={theme}
                                  className="empty-cart-message"
                                  mt={3}
                                >
                                  <Typography
                                    className="text-dark"
                                    theme={theme}
                                    variant="subtitle1"
                                    component="p"
                                  >
                                    Your cart is lonely!
                                  </Typography>
                                  <Typography
                                    className="text-muted"
                                    theme={theme}
                                    variant="caption"
                                    component="small"
                                  >
                                    Looks like you have not added anything in your cart
                                    yet. Search for items to start adding them here.
                                  </Typography>
                                </Box>
                              </Box>
                              )
                            : (
                              <>
                                <CheckoutItemList
                                  cart={cart?.cart}
                                  buttonText={'Start For Free'}
                                  onClickHandle={handleCheckout}
                                  buttonDisplay
                                />
                              </>
                              )}
                        </Box>
                      </>
                    }
                    options={[]}
                    render={(cb) => (
                      <>
                        <a
                          className="nav-link"
                          href="javascript:void(0)"
                          onClick={cb}
                        >
                          <img src={CartIcon} alt="Cart" />
                          <span className="cart-counter">{cart?.cart?.length}</span>
                        </a>
                      </>
                    )}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                  />
                </li>
                )
              : null
          }
          <MenuComp />
        </ul>
      </ResponsiveHeaderMenu>
    </CustomAppBar>
  )
}
export default ResponsiveAppBar
